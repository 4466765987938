<template>
  <div>
    <form autocomplete="off">
      <div class="input-group">
        <label>Address Line 1</label>
        <input
          required
          v-model="address.address1"
          type="text"
          autocomplete="address-line1"
        />
      </div>
      <div class="input-group">
        <label
          >Address Line 2
          <span class="text-gray-600 ml-2"> (Optional)</span></label
        >
        <input
          v-model="address.address2"
          type="text"
          autocomplete="address-line2"
        />
      </div>
      <div class="input-group">
        <label>Town / City</label>
        <input required v-model="address.address3" type="text" />
      </div>
      <div class="input-group">
        <label>Postcode</label>
        <div class="w-32">
          <input
            required
            v-model.lazy="address.postcode"
            type="text"
            autocomplete="postal-code"
            maxlength="8"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { checkPostcode } from '@/utilities/checkPostcode';

export default {
  props: {
    showError: {
      type: Boolean,
      default: false
    },
    address: {
      type: Object
    },
    validatePostcode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  computed: {
    formValid() {
      return this.address.address1 && this.address.address3 && this.address.postcode;
    },
    postcodeChecker() {
      return checkPostcode(this.address?.postcode, this.validatePostcode);
    }
  },
  watch: {
    formValid(newValue) {
      this.$emit('is-valid', newValue);
    }
  },
  created() {
    this.$emit('is-valid', this.formValid);
  }
};
</script>

<style lang="css" scoped></style>
