<template>
  <div v-if="activeOptions && deliveryOptions" class="space-y-4">
    <div class="border divide-y rounded-lg">
      <div v-for="option in activeOptions" :key="option.prices.id">
        <label
          class="space-x-3  w-full flex flex-col py-3 sm:py-2 px-3"
          :for="`delivery-option-${option.prices[0].id}`"
        >
          <div class="flex items-center space-x-3">
            <input
              :id="`delivery-option-${option.prices[0].id}`"
              type="radio"
              :value="option"
              v-model="selectedShippingOption"
            />
            <div class="flex-grow">
              {{
                !option.prices[0].remote
                  ? option.prices[0].cost === 0
                    ? 'Up To 4 Working Days'
                    : 'Next Day Delivery'
                  : option.courier
              }}
            </div>
            <div
              class="line-through text-gray-600"
              v-if="option.prices[0].discountPrice"
            >
              {{ option.prices[0].discountPrice.displayCost }}
            </div>
            <span class="font-medium">
              {{
                option.prices[0].cost === 0
                  ? 'Free'
                  : `+ ${option.prices[0].displayCost}`
              }}</span
            >
          </div>

          <div
            class="text-sm text-gray-600 pl-1 mt-2 pb-2"
            v-if="!option.prices[0].remote"
          >
            <div v-if="option.type === 'dpd'">
              - Next Working Day after dispatch<br />- Most popular!
            </div>
            <div v-if="option.type === 'yodel'">
              - Up to 4 working days after dispatch
            </div>
          </div>
        </label>
      </div>
    </div>
    <div v-if="activeMessage" class="text-gray-700 italic text-sm px-2 ">
      {{ messages[activeMessage] }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { orderBy, cloneDeep, isObject } from 'lodash/fp';
export default {
  name: 'DeliveryOptions',
  props: {
    postcode: {
      type: [Boolean, Object]
    },
    subscriptionPrice: {
      type: Number
    },
    activeOption: {
      type: String
    },
    reverse: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedShippingOption: null,
      activeMessage: null
    };
  },
  methods: {
    checkActive(price) {
      let active = false;

      if (this.postcode) {
        if (price.remote) {
          active = true;
          if (price.id.includes('yodel')) {
            active = true;
          } else {
            active = false;
          }
          this.activeMessage = 'remote';
        } else {
          active = false;
          this.activeMessage = null;
        }
      } else {
        if (this.subscriptionPrice > 10000) {
          active = true;
          this.activeMessage = null;
        } else {
          active = true;
          this.activeMessage = null;
        }
      }

      return active;
    }
  },
  computed: {
    ...mapGetters({
      deliveryOptions: 'deliveryOptionsGetter'
    }),
    messages() {
      return {
        remote: `In order to send to certain areas we charge £2.99 to cover the extra delivery costs that we incur. Please be aware it can take slightly longer to deliver to these areas.`,
        free: `You've qualified for free upgraded delivery`
      };
    },
    canDiscount() {
      return true;
    },
    setHide() {
      const isHide =
        this.selectedShipping?.type === 'dpd' &&
        this.selectedShipping?.prices[0].cost === 0 &&
        !this.postcode;

      return isHide;
    },
    activeOptions() {
      const self = this;
      const options = cloneDeep(this.deliveryOptions?.subscription);
      if (options) {
        options.forEach(s => {
          // filter out items that are not userSelectable
          s.prices = s.prices.filter(p => p?.userSelectable);

          s.prices.forEach(p => {
            this.$set(p, 'active', this.checkActive(p));
          });

          s.prices = s.prices.filter(ps => ps.active);
        });

        const filteredOptions = orderBy(['prices[0].cost'], ['asc'], options);

        self.selectedShippingOption = filteredOptions[0];
        if (self.activeOption && !isObject(self.postcode)) {
          self.selectedShippingOption = filteredOptions.find(op =>
            op.prices.find(pp => pp.id === self.activeOption)
          );
        }

        const order = orderBy(
          ['courier'],
          ['desc'],
          filteredOptions.filter(f => f.prices.length > 0)
        );

        // reverse the order is reverse is true
        if (self.reverse) {
          order.reverse();
        }

        return order;
      }
      return [];
    }
  },
  watch: {
    selectedShippingOption: {
      immediate: true,
      handler(val) {
        if (val) {
          this.$emit('input', val);

          this.$store.commit('set_delivery_option', val);
          const isHide =
            val?.type === 'dpd' && val?.prices[0].cost === 0 && !this.postcode;

          this.$emit('set-hide', isHide);
        }
      }
    }
    // setHide: {
    //   immediate: true,
    //   handler(val) {
    //     console.log('sh', val);
    //     this.$emit('set-hide', val);
    //   }
    // }
  }
};
</script>

<style></style>
